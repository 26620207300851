




































import { Notify,Toast } from "vant"
import { HandAllItem } from "@/Type/index"
import { HandSucColl } from "@/Api/Hand/index"
import Stroage from "@/util/Storage"
import { Vue,Component,Prop, Watch, Emit } from "vue-property-decorator"

interface HandHeadType<T=string>{
    loadingShow:boolean
    userId:T|number;
    token:T;
    CollIcon:T;
    ACollIcon:T;
    CollShow:boolean

    initData():void;
    handleClickColl():void;
    handleError(data:string):void;
}

@Component({ name:"HandHead" })
export default class HandHead extends Vue implements HandHeadType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    CollIcon = require("$icon/Hand/coll.png");
    ACollIcon = require("$icon/Hand/ActiveColl.png");
    CollShow = false

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token") 
       this.initData()   
   }

   get GetTime(){
       if( "id" in this.GetData ){
           return this.GetData.updateTime.split("T")[0]
       }else{
           return ""
       }
   }

   initData(){
       let reg= /true/g
       if( reg.test( String( this.GetData.alreadyFollow ) ) ){
           this.CollShow = true
       }else{
           this.CollShow = false
       }
   }

   handleClickColl(){
       this.loadingShow = true
       HandSucColl({
           userId:this.userId,
           token:this.token
       },{
           id:this.$route.query.id as string
       }).then(res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               let Str = ""
               if( this.CollShow ){
                   Str = "取消成功"
               }else{
                   Str = "收藏成功"
               }
               Toast({
                   message:Str,
                   icon:"none",
                   className:"ToastClassName"
               })
               this.pullReftch()
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

   @Prop(Object)
   data!:HandAllItem

   get GetData(){
       return this.data
   }

   @Watch("GetData")
   ChangeGetData(){
       this.initData()
   }

   @Emit("pullReftch")
   pullReftch(){
       return true
   }
}
