






















































import HandCommentC from "@/components/HandComponent/HandSuc/Comment.vue"
import { HandAllItem } from "@/Type/index"
import { Vue,Component,Emit, Prop, Watch } from "vue-property-decorator"

interface HeadCommentType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    DefaultIcon:T;
    ChatIcon:T;
    DelIcon:T;
    List:Array<any>
    Show:boolean;
    CommentData:{ helpId?:T|number;toId?:T|number; }

    handleClickComment():void;
    handleChangeShow():void;
}

@Component({ name:"HandComment",components:{ HandCommentC } })
export default class HandComment extends Vue implements HeadCommentType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    DefaultIcon = require("$icon/Basics/default.png");
    ChatIcon = require("$icon/Help/Chat.png");
    DelIcon = require("$icon/Help/Del.png");
    Show = false;
    CommentData = {}
    List:Array<any> = []

    handleClickComment(){
        let data = {
            helpId:0,
            toId:0
        }
        this.CommentData = data
        this.handleChangeShow()
    }

    handleChangeShow(){
        this.Show = !this.Show
    }

    @Prop(Boolean)
    show!:boolean

    get GetShow(){
        return this.show
    }

    @Watch("GetShow")
    ChangeGetShow(newVal:boolean){
        this.Show = newVal
    }

    @Watch("Show")
    ChangeShow(newVal:boolean){
       this.PullShow( newVal )
    }

    @Emit("PullShow")
    PullShow(data:boolean){
        return data
    }

    @Emit("PullReftch")
    PullReftch(){
        return true
    }

   @Prop(Object)
   data!:HandAllItem

   get GetData(){
       return this.data
   }
}
