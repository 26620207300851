














































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import HandBanner from "@/components/HandComponent/HandSuc/HandBanner.vue"
import HandHead from "@/components/HandComponent/HandSuc/HandHead.vue"
import HandCont from "@/components/HandComponent/HandSuc/HandCont.vue"
import HandUser from "@/components/HandComponent/HandSuc/HandUser.vue"
import HandComment from "@/components/HandComponent/HandSuc/HandComment.vue"
import HandFooter from "@/components/HandComponent/HandSuc/HandFooter.vue"
import Stroage from "@/util/Storage"
import { HandAllItem } from "@/Type/index"
import { HandSuc as HandUpSuc } from "@/Api/Hand/index"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface HandSucType<T=string>{
    loadingShow:boolean
    userId:T|number;
    token:T;
    CommentShow:boolean
    data:HandAllItem

    handleChangeShow(data:boolean):void;
    handleUpData():void;
    handleError(data:string):void;
}

@Component({ name:"HandSuc",components:{ HandBanner,HandHead,HandCont,HandUser,HandComment,HandFooter,HeadTop } })
export default class HandSuc extends ZoomPage implements HandSucType{
   loadingShow = true
   userId:string|number = ""
   token = ""
   CommentShow = false
   data:HandAllItem = {}

   activated() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.handleUpData()
   }

   handleChangeShow(data:boolean){
       this.CommentShow = data
   }

   handleUpData(){
       HandUpSuc({
           userId:this.userId,
           token:this.token
       },{
           id:this.$route.query.id as string
       }).then(res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               this.data = res.data
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
