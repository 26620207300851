














 import { HandAllItem } from "@/Type/index"
 import { ImagePreview } from 'vant';
 import { Vue,Component, Prop, Watch } from "vue-property-decorator"
 
 interface HandBannerType<T=string>{
     ImgList:Array<string>
     Show:boolean

     initData():void;
     handlePreview(index:number):void;
 }

 @Component({ name:"HandBanner" })
 export default class HandBanner extends Vue implements HandBannerType{
    ImgList: string[] = [
        "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg",
        "https://dss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1089874897,1268118658&fm=26&gp=0.jpg",
    ]
    Show = true

    mounted() {
        this.initData()   
    }

    initData(){
        if( this.GetData.pics?.length ){
              this.ImgList = this.GetData.pics.split(",")
              this.Show = true
         }else{
             this.Show = false
         }
    }

    handlePreview(index:number){
        ImagePreview({
            images:this.ImgList,
            startPosition:index
        })
    }

    @Prop(Object)
    data!:HandAllItem

    get GetData(){
        return this.data
    }

    @Watch("GetData")
    ChangeGetData(){
        this.initData()   
    }
}
