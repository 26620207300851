




















import { Vue,Component, Prop, Watch, Emit } from "vue-property-decorator"

interface HandSelItemType<T=string>{
    SelList:Array<{ text:T;state:number; }>
    Show:boolean;

    handleChangeShow():void;
    handleClickItem(data:{ text:T;state:number; },index:number):void;
}

@Component({ name:"HandSelItem" })
export default class HandSelItem extends Vue implements HandSelItemType{
    SelList = [
        { text:"出售中", state:0, },
        { text:"已卖出", state:1, },
        { text:"已删除", state:2, },
    ]
    Show = false

    handleChangeShow(){
        this.PullShow( false )
    }

    handleClickItem(data:{ text:string;state:number; },index:number){
        this.PullData( data )
        this.PullShow( false )
    }

    @Prop(Boolean)
    show!:boolean

    get GetShow(){
        return this.show
    }

    @Watch("GetShow")
    ChangeGetShow(newVal:boolean){
        this.Show = newVal
    }

    @Emit("PullShow")
    PullShow(data:boolean){
        return data
    }

    @Emit("PullData")
    PullData(data:{ text:string;state:number; }){
        return data
    }
}
