






























































import { Toast,Notify,Dialog, } from "vant"
import HandSelItem from "@/components/HandComponent/HandSuc/HandSelItem.vue"
import Stroage from "@/util/Storage"
import { HandAllItem } from "@/Type/index"
import { HandSucColl,HandChangeState,HandRemove } from "@/Api/Hand/index"
import { Vue,Component,Ref,Emit, Prop, Watch } from "vue-property-decorator"

interface HandFooterType<T=string>{
    loadingShow:boolean
    userId:T|number;
    token:T;
    NavList:Array<{ icon:T;aIcon?:T;active?:boolean;content:T; }>
    AdminListLeft:Array<{ icon:T;content:T;aIcon?:T;active?:boolean; }>
    AdminListRight:Array<{ icon:T;content:T; }>
    AdminBool:boolean
    CollShow:boolean
    StateShow:boolean

    initData():void;
    handleUserState(index:number):void;
    handleAdminUser(index:number):void;
    handleAdminState(index:number):void;
    handleClickChat():void;
    handleClickMessage():void;
    handleClickColl():void;
    handleClickPay():void;
    handleState(data:any):void;
    handleRemove():void;
    handleCopyPhone(str:string):void;
    handleChangeShow(data:boolean):void;
    handleError(data:string):void;
}

@Component({ name:"HandFooter",components:{ HandSelItem } })
export default class HandFooter extends Vue implements HandFooterType{
   loadingShow = false
   userId:string|number = ""
   token = ""
   NavList = [
       {
           icon:require("$icon/Hand/Chat_Shop.png"),
           content:"咨询"
       },
       {
           icon:require("$icon/Hand/message.png"),
           content:"留言"
       },
       {
           icon:require("$icon/Hand/coll.png"),
           aIcon:require("$icon/Hand/ActiveColl.png"),
           active:false,
           content:"收藏"
       },
   ] 
   AdminListLeft = [
       {
           icon:require("$icon/Hand/message.png"),
           content:"留言"
       },
       {
           icon:require("$icon/Hand/coll.png"),
           aIcon:require("$icon/Hand/ActiveColl.png"),
           active:false,
           content:"收藏"
       },
   ]
   AdminListRight = [
       {
           icon:require("$icon/Hand/OrderState.png"),
           content:"商品状态"
       },
       {
           icon:require("$icon/Hand/Del.png"),
           content:"下架商品"
       },
   ]
   AdminBool = false
   CollShow = false
   StateShow = false

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")  
       if( "admin" in this.$route.query ){
           this.AdminBool = false
       }else{
           this.AdminBool = true
       }
       this.initData()
   }

   initData(){
       let reg= /true/g
       if( reg.test( String( this.GetData.alreadyFollow ) ) ){
           this.NavList[2].active = true
           this.AdminListLeft[1].active = true
           this.CollShow = true
       }else{
           this.NavList[2].active = false
           this.AdminListLeft[1].active = false
           this.CollShow = false
       }
   }

   handleUserState(index:number){
       switch( index ){
           case 0:
               this.handleClickChat()
               break;
           case 1:
               this.handleClickMessage()
               break;
           case 2:
               this.handleClickColl()
               break;    
       }
   }

   handleAdminUser(index:number){
       if( index ){
           this.handleClickColl()
       }else{
           this.handleClickMessage()
       }
   }

   handleAdminState(index:number){
       if( index ){
           this.handleRemove()
       }else{
           this.handleChangeShow(true)
       }
   }

   handleClickChat(){
       Dialog.confirm({
           title: '温馨提醒',
           message: "功能正在完善中,请线下交易，是否复制商家电脑 "+ 18337390930 || "0371-55883688",
           confirmButtonText:"复制电话",
           className:"HideClassName",
           overlayClass:"MaskHideClassName"
       }).then(res=>{
           this.handleCopyPhone( '18337390930' )
       }).catch(cat=>cat)
   }

   handleClickMessage(){
       this.PullShow(!this.GetShow)
   }

   handleClickColl(){
       this.loadingShow = true
       HandSucColl({
           userId:this.userId,
           token:this.token
       },{
           id:this.$route.query.id as string
       }).then(res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               let Str = ""
               if( this.CollShow ){
                   Str = "取消成功"
               }else{
                   Str = "收藏成功"
               }
               Toast({
                   message:Str,
                   icon:"none",
                   className:"ToastClassName"
               })
               this.PullReftch()
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleClickPay(){
       this.handleClickChat()
   }

   handleState(data:any){
       this.loadingShow = true
       HandChangeState({
           userId:this.userId,
           token:this.token
       },{
           id:this.$route.query.id  as string,
           status:data.state 
       }).then(res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               Toast({
                   message:"修改成功",
                   icon:"none",
                   className:"ToastClassName"
               })
               this.PullReftch()
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleRemove(){
       this.loadingShow = true
       HandRemove({
           userId:this.userId,
           token:this.token
       },{
           id:this.$route.query.id  as string,
       }).then(res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               Toast({
                   message:"删除成功",
                   icon:"none",
                   className:"ToastClassName"
               })
               let time = setTimeout(()=>{
                   this.$router.go(-1)
                   clearTimeout(time)
               },1000)
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

   handleCopyPhone(str:string){
       if( !str.length )return;
       this.GetCopy.value = str
       this.GetCopy.select()
       document.execCommand("copy");
       Toast({
           message:"复制成功",
           icon:"none",
           className:"ToastClassName"
       })
   }

   handleChangeShow(data:boolean){
       this.StateShow = data
   }

   @Prop(Boolean)
   show!:boolean

   get GetShow(){
       return this.show
   }

   @Ref("Copy")
   Copy!:HTMLInputElement;
   get GetCopy(){
       return this.Copy
   }

   @Emit("PullShow")
   PullShow(data:boolean){
       return data
   }

   @Emit("PullReftch")
   PullReftch(){
       return true
   }

   @Prop(Object)
   data!:HandAllItem

   get GetData(){
       return this.data
   }

   @Watch("GetData")
   ChangeGetData(){
       this.initData()
   }
}
