











































import { HandAllItem } from "@/Type/index"
import { Vue,Component,Prop } from "vue-property-decorator"

interface HandUserType<T=string>{
    DefaultIcon:T;
}

@Component({ name:"HandUser" })
export default class HandUser extends Vue implements HandUserType{
    DefaultIcon = require("$icon/Basics/default.png")

   @Prop(Object)
   data!:HandAllItem

   get GetData(){
       return this.data
   }
}
