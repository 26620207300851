



















import { Notify,Toast } from "vant"
import { Vue,Component,Prop,Watch,Emit } from "vue-property-decorator"

interface CommentType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    Value:T;
    Show:boolean;

    handleToggleShow():void;
    handleClickBtn():void;
    handleError(data:string):void;
}

@Component({ name:"Comment" })
export default class Comment extends Vue implements CommentType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    Show = false;
    Value = ""

    handleToggleShow(){
        this.Show = !this.Show
    }

    handleClickBtn(){
        this.Value = this.Value.replace(/ /g,"")
        if( !this.Value.length ){
           Toast({
               message:"请输入内容",
               icon:"none",
               className:"ToastClassName"
           })
           return;
        }
        Toast({
           message:"暂未开放功能",
           icon:"none",
           className:"ToastClassName"
       })
        
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

    @Prop(Object)
    data!:any

    get GetData(){
        return this.data
    }

    @Prop(Boolean)
    show!:boolean

    get GetShow(){
        return this.show
    }

    @Watch("GetShow")
    handleChangeShow(newVal:boolean){
        this.Show = newVal
    }

    @Watch("Show")
    handleShow(newVal:boolean){
        if( newVal ){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
            this.pullShow()
        }
    }

   @Emit("pullShow")
   pullShow(){
       return false
   }

   @Emit("PullReftch")
   PullReftch(){
       return true
   }
}
