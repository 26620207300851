










import { HandAllItem } from "@/Type/index"
import { Vue,Component,Prop } from "vue-property-decorator"

@Component({ name:"HandCont" })
export default class HandCont extends Vue{

   @Prop(Object)
   data!:HandAllItem

   get GetData(){
       return this.data
   }
}
